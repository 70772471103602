var Login = {
    initPasswordField: function () {
        $('[data-attr="password-field"] + label.field-icon-show').click(function () {
            var inputNode = $(this).prev();
            if (inputNode.attr('type') === 'password') {
                inputNode.attr('type', 'text');
                $(this).text('HIDE');
                $(this).addClass('red');
            } else {
                inputNode.attr('type', 'password');
                $(this).text('SHOW');
                $(this).removeClass('red');
            }
            inputNode.focus();
        });
    },
    initBackground: function () {
        // Init CanvasBG and pass target starting location
        CanvasBG.init({
            Loc: {
                x: window.innerWidth / 2,
                y: window.innerHeight / 3.3
            },
        });
    },

    passwordValidation: {
        init: function () {
            if ($('.rule').length) {
                $('#password, #passwordConfirm')
                    .unbind('change').change(Login.passwordValidation.validate)
                    .unbind('keyup').keyup(Login.passwordValidation.validate)
                    .unbind('blur').blur(Login.passwordValidation.validate);
            }
        },
        validate: function () {
            var password = $('#password').val();
            $('.rule').each(function () {
                switch ($(this).attr('data-rule')) {
                    case 'minPasswordLength':
                        if (parseInt($(this).attr('data-value')) <= password.length) {
                            $(this).addClass('ok');
                        } else {
                            $(this).removeClass('ok');
                        }
                        break;
                    case 'uppercase':
                        if (password.match(/[A-Z]/)) {
                            $(this).addClass('ok');
                        } else {
                            $(this).removeClass('ok');
                        }
                        break;
                    case 'lowercase':
                        if (password.match(/[a-z]/)) {
                            $(this).addClass('ok');
                        } else {
                            $(this).removeClass('ok');
                        }
                        break;
                    case 'number':
                        (password.match(/[0-9]/))
                            ? $(this).addClass('ok')
                            : $(this).removeClass('ok');
                        break;
                    case 'symbol':
                        (password.match(/[^\p{L}\d\s@#]/u))
                            ? $(this).addClass('ok')
                            : $(this).removeClass('ok');
                        break;
                    case 'match':
                        if (password === $('#passwordConfirm').val()) {
                            $(this).addClass('ok');
                        } else {
                            $(this).removeClass('ok');
                        }
                        break;
                    default:
                        console.log('unknown rule', $(this).attr('data-rule'));
                }
            });
        }
    }
};
$(function () {
    Login.initPasswordField();
    Login.initBackground();
    Login.passwordValidation.init()
});

